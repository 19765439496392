import { EventEmitter } from "events";
import { v4 as uuid } from "uuid";

class NotificationManager extends EventEmitter {
  constructor() {
    super();
    this.notifications = [];
  }

  create(notify) {
    const defaultNotify = {
      id: uuid(),
      timeout: 5000,
      ...notify
    };
    this.notifications = [defaultNotify, ...this.notifications.slice(0, 5)];
    this.emitChange();
  }

  remove(id) {
    this.notifications = this.notifications.filter(n => id !== n.id);
    this.emitChange();
  }

  emitChange() {
    this.emit("change", this.notifications);
  }

  addChangeListener(callback) {
    this.addListener("change", callback);
  }

  removeChangeListener(callback) {
    this.removeListener("change", callback);
  }
}

export default new NotificationManager();
