const images = {
  LogoSmall: require("./logos/fanyuvo_logo.png"),
  LogoBig: require("./logos/fanyuvo_logo_big.png"),
  LogoSmallEn: require("./logos/fanyuvo_logo_en.png"),
  LogoBigEn: require("./logos/fanyuvo_logo_big_en.png"),
  LogoHome: require("./logos/BETYAR_logo_home.png"),
  LogoHeader: require("./logos/BETYAR_logo_header.png"),
  BackgroundGreen: require("./backgrounds/betyaros_background_green.jpg"),
  BackgroundRed: require("./backgrounds/betyaros_background_red.jpg"),
  HomeChef: require("./home/home_chef.png"),
  HomeDelivery: require("./home/home_delivery.png"),
  HomePrivate: require("./home/home_private.png")
};

export default images;
