import React, { useEffect } from "react";
import { useContext } from "react";
import { OrderContext } from "contexts/OrderContext";
import useQueryParams from "hooks/useQueryParams";
import useScrollTop from "hooks/useScrollTop";
import { ORDER_SORT_TYPE } from "utils/contants";
import OrderTable from "components/orders";

const AllOrders = () => {
  const {
    state: { all: orders, count, loading },
    fetchAllOrders,
    cleanUp
  } = useContext(OrderContext);

  const [{ page, sort = "date", desc = "true" }] = useQueryParams();
  const currentPage = page > 0 ? page : 1;

  useScrollTop(currentPage);

  useEffect(() => {
    const sortConfig = {
      by: ORDER_SORT_TYPE[sort],
      desc: JSON.parse(desc)
    };
    fetchAllOrders(currentPage, sortConfig);
    return () => cleanUp();
  }, [fetchAllOrders, cleanUp, currentPage, sort, desc]);

  return (
    <OrderTable
      orders={orders}
      loading={loading}
      pagination={{ itemsNumber: count }}
    />
  );
};

export default AllOrders;
