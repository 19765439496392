import React, { useContext } from "react";
import { Container, Title, Button } from "style/global.styled";
import { CartContext } from "contexts/CartContext";
import { getProductsCount } from "utils/util";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Message } from "components/utils";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  CartTable,
  TotalPrice,
  CouponForm,
  DeliveryTimeNotification
} from "components/cart";

const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  min-height: 65vh;
`;

const EmptyCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 321px);
  @media (max-width: 1200px) {
    min-height: calc(100vh - 130px);
  }
`;

const Cart = () => {
  const { t: translate } = useTranslation();

  const {
    cart: { products }
  } = useContext(CartContext);

  const groupedProducts = getProductsCount(products);

  if (products.length === 0)
    return (
      <Container>
        <EmptyCart>
          <Message
            icon={faShoppingBasket}
            title={translate("cart.emptyCart")}
            text={
              <Link to="/foods">
                <Button>{translate("cart.goToMenu")}</Button>
              </Link>
            }
          />
        </EmptyCart>
      </Container>
    );

  return (
    <Container>
      <DeliveryTimeNotification />
      <Wrapper>
        <Title size={40} color="dark">
          {translate("cart.title")}
        </Title>
        <CartTable products={groupedProducts} />
        <CouponForm />
        <TotalPrice />
      </Wrapper>
    </Container>
  );
};

export default Cart;
