import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/UserContext";
import { SubNavigation, SubNavItem } from "style/global.styled";

const ProfileNavigation = () => {
  const { t: translate } = useTranslation();
  const { logOut } = useContext(UserContext);
  const { subpage = "" } = useParams();
  const subpages = ["", "addresses", "orders"];

  return (
    <SubNavigation>
      {translate("profileNavigation", { returnObjects: true }).map(
        (text, index) => (
          <SubNavItem
            key={index}
            to={`/profile/${subpages[index]}`}
            selected={subpage === subpages[index]}
          >
            {text}
          </SubNavItem>
        )
      )}
      <SubNavItem to="#" onClick={() => logOut()}>
        {translate("logout")}
      </SubNavItem>
    </SubNavigation>
  );
};

export default ProfileNavigation;
