import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "style/global.styled";
import { useContext } from "react";
import { CartContext } from "contexts/CartContext";
import useFormValues from "hooks/useFormValues";
import Coupon from "models/coupon.model";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";

const Form = styled.form`
  display: flex;
  max-width: 350px;
  margin-top: 50px;
  ${Button} {
    margin: 0 0 0 20px;
    height: 45px;
    @media (max-width: 768px) {
      min-width: unset;
    }
  }
`;

const CustomTextField = styled(TextField)`
  margin: 0;
  padding-top: 0;
  font-size: 20px;
  & > div:nth-child(1) {
    max-height: 45px;
  }
`;

const CouponForm = () => {
  const { t: translate } = useTranslation();
  const { redeemCopuon } = useContext(CartContext);

  const init = { coupon: "" };

  const [error, setError] = useState("");
  const [values, handleChange, setValues] = useFormValues(init);

  const handleSubmit = e => {
    e.preventDefault();
    const { coupon } = values;
    if (coupon !== "") {
      redeemCopuon(new Coupon({ code: coupon }))
        .then(() => setValues(init))
        .catch(err => setError(err));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CustomTextField
        name="coupon"
        placeholder={translate("cart.couponCode")}
        onChange={handleChange}
        value={values.coupon}
        error={error && translate(`error.${error}`)}
        onFocus={() => setError("")}
      />
      <Button type="submit">{translate("cart.redeem")}</Button>
    </Form>
  );
};

export default CouponForm;
