import { Selector } from "components/utils";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SubTitle } from "style/global.styled";
import styled from "styled-components";

const Selectors = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > :nth-child(1) {
    width: 310px;
    margin-right: 10px;
  }
  & > :nth-child(2) {
    width: 180px;
  }
  @media (max-width: 600px) {
    & > :nth-child(1),
    & > :nth-child(2) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const dateFormat = "MMMM Do dddd";

const ShippingTime = ({ value, onChange }) => {
  const { t: translate } = useTranslation();
  const [date] = useState(value.date);
  const [date2] = useState(new Date(new Date().setDate(date.getDate() + 1)));
  const [date3] = useState(new Date(new Date().setDate(date.getDate() + 2)));
  const [date4] = useState(new Date(new Date().setDate(date.getDate() + 3)));

  useEffect(() => {
    if (value.date === date) return;
    if (new Date(value.date).getDate() > date.getDate()) {
      onChange({ name: "time", value: "12:00" });
    } else {
      onChange({ name: "time", value: "now" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.date]);

  const generateTimes = () => {
    const times = [];
    const date = value.date ? new Date(value.date) : new Date();
    const comparingHours = date.getHours() + 2;

    const date2 = new Date();
    date2.setHours(comparingHours);

    const lastDate = new Date(date);
    lastDate.setHours(22);
    lastDate.setMinutes(0);

    for (let i = 12; i <= 22; i++) {
      if (comparingHours <= i || date.getDate() > date2.getDate()) {
        for (let j = 0; j <= 45; j += 15) {
          const compareDate = new Date(date);
          compareDate.setHours(i);
          compareDate.setMinutes(j);
          if (date2 < compareDate && compareDate <= lastDate) {
            times.push(moment(compareDate).format("H:mm"));
          }
        }
      }
    }
    return times;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    onChange({ name, value });
  };

  return (
    <>
      <SubTitle>{translate("payment.orderDateTitle")}</SubTitle>
      <Selectors>
        <Selector
          name="date"
          value={value.date || date}
          onChange={handleChange}
        >
          <option value={date}>{moment(date).format(dateFormat)}</option>
          <option value={date2}>{moment(date2).format(dateFormat)}</option>
          <option value={date3}>{moment(date3).format(dateFormat)}</option>
          <option value={date4}>{moment(date4).format(dateFormat)}</option>
        </Selector>
        <Selector name="time" value={value.time} onChange={handleChange}>
          {new Date(value.date).getDate() <= date.getDate() && (
            <option value="now">{translate("now")}</option>
          )}
          {generateTimes().map(time => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </Selector>
      </Selectors>
    </>
  );
};

export default ShippingTime;
