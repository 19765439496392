import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "contexts/UserContext";
import Loader from "../loader";
import { Wrapper } from "style/global.styled";

const PrivateRoute = ({ roles, ...props }) => {
  const {
    state: { user, isLoggedIn, loading }
  } = useContext(UserContext);

  if (loading)
    return (
      <Wrapper>
        <Loader size="70px" />
      </Wrapper>
    );

  if (!isLoggedIn && props.path !== "/login") return <Redirect to="/login" />;
  if (!isLoggedIn && props.path === "/login") return <Route {...props} />;

  if (!roles) return <Route {...props} />;

  if (roles && roles.includes(user.role)) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

export default PrivateRoute;
