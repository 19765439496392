import React, { useState, useEffect, createRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import Textbox from "./textbox";
import Checkbox from "./checkbox";
import { useTranslation } from "react-i18next";
import { ErrorMsg } from "style/global.styled";
import TextArea from "./tex-area";

const Container = styled.div`
  width: 100%;
  padding: 18px 0;
  overflow: visible;
`;

const TextField = props => {
  const { t: translate } = useTranslation();
  const [required, setRequired] = useState(false);

  const inputRef = createRef();

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      if (
        (input.type !== "checkbox" && input.value) ||
        (input.type === "checkbox" && input.checked)
      ) {
        input.setCustomValidity("");
        setRequired(false);
      }
    }
  }, [inputRef]);

  const handleRequired = e => {
    e.target.setCustomValidity(" ");
    setRequired(true);
  };

  const disableEnter = e => {
    if (e.key === "Enter") e.target.blur();
  };

  const getField = type => {
    if (type === "checkbox")
      return <Checkbox {...props} ref={inputRef} onInvalid={handleRequired} />;
    else if (type === "textarea")
      return <TextArea {...props} ref={inputRef} onInvalid={handleRequired} />;
    return (
      <Textbox
        {...props}
        ref={inputRef}
        onInvalid={handleRequired}
        onKeyPress={disableEnter}
      />
    );
  };

  return (
    <ThemeProvider
      theme={{ isError: !!props.error || required, hasIcon: !!props.icon }}
    >
      <Container className={props.className}>
        {getField(props.type)}
        <ErrorMsg>
          {required ? translate("error.required") : props.error}
        </ErrorMsg>
      </Container>
    </ThemeProvider>
  );
};

export default TextField;
