import React from "react";
import useWindowSize from "hooks/useWindowSize";
import { EqualTableColumn, TableRow } from "style/global.styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { deleteProduct } from "utils/dataHandler";
import { Private } from "components/utils";
import { ROLES } from "utils/contants";

const CustomTableColumn = styled(EqualTableColumn)`
  :first-child {
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 900px) {
      text-align: right;
    }
  }
`;

const ResponsiveRow = styled(TableRow)`
  ${CustomTableColumn} {
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    header {
      font-weight: bold;
    }
    button {
      width: 100%;
    }
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.lightGreen};
  div {
    cursor: pointer;
    margin: 0 8px;
    transition: opacity 0.3s ease;
    :hover {
      opacity: 0.7;
    }
  }
`;

const ProductRow = ({ product, onEdit, onDeleted, isEditing }) => {
  const { t: translate, i18n } = useTranslation();
  const lang = i18n.language;

  const { id, name, nameEnglish, type, group, orderable } = product;

  const headers = translate("dashboard.products.headers", {
    returnObjects: true
  });

  const handleEdit = () => {
    const toSet = isEditing ? null : product;
    onEdit(toSet);
  };

  const handleDelete = () => {
    deleteProduct(id)
      .then(() => onDeleted(id))
      .catch(err => console.log(err));
  };

  const buttons = (
    <Buttons>
      <div onClick={handleEdit}>
        {!isEditing ? translate("edit") : translate("close")}
      </div>
      <Private roles={[ROLES.ADMIN, ROLES.MANAGER]}>
        <div onClick={handleDelete}>{translate("delete")}</div>
      </Private>
    </Buttons>
  );

  const orderableText = orderable
    ? translate(`products.orderable`)
    : translate(`products.notOrderable`);

  const columns = [
    lang === "hu" ? name : nameEnglish,
    translate(`products.types.${type}`),
    translate(`products.groups.${group}`),
    orderableText,
    buttons
  ];

  const { width } = useWindowSize();

  return width > 1000 ? (
    <TableRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length}>
          {column}
        </CustomTableColumn>
      ))}
    </TableRow>
  ) : (
    <ResponsiveRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length}>
          {<header>{headers[index].text}</header>}
          {column}
        </CustomTableColumn>
      ))}
    </ResponsiveRow>
  );
};

export default ProductRow;
