import React from "react";
import OrderRow from "./order-row";
import { BaseTable } from "components/utils";
import { useTranslation } from "react-i18next";

const OrderTable = ({ className, orders, loading, pagination, isProfile }) => {
  const { t: translate } = useTranslation();

  return (
    <BaseTable
      className={className}
      loading={loading}
      headers={translate("orders.headers", { returnObjects: true })}
      pagination={pagination}
      isProfile={isProfile}
    >
      {orders.map(order => (
        <OrderRow key={order.id} order={order} isProfile={isProfile} />
      ))}
    </BaseTable>
  );
};

export default OrderTable;
