import React from "react";
import { Wrapper, Text } from "style/global.styled";
import { Loader } from "components/utils";
import SorterTableHead from "./sorter-table-head";
import Pagination from "../pagination";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";

const Table = styled.div`
  width: 90%;
  margin: 50px auto 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EmptyTableText = styled(Text)`
  font-weight: bold;
  text-align: center;
  margin: 150px 0;
`;

const BaseTable = ({
  className,
  children,
  headers,
  loading,
  pagination,
  isProfile
}) => {
  const { t: translate } = useTranslation();

  const content = _.isEmpty(children) ? (
    <EmptyTableText>{translate("noData")}</EmptyTableText>
  ) : (
    <>
      {children}
      {pagination && <Pagination itemsNumber={pagination.itemsNumber} />}
    </>
  );

  return (
    <Table className={className}>
      <SorterTableHead headers={headers} isProfile={isProfile} />
      {!loading ? (
        content
      ) : (
        <Wrapper>
          <Loader size="60px" />
        </Wrapper>
      )}
    </Table>
  );
};

export default BaseTable;
