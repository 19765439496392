import React, { useState } from "react";
import "moment/locale/hu";
import moment from "moment";
import { TableRow, EqualTableColumn } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { ViewButton } from "components/utils";
import UserDetails from "./user-details";
import styled from "styled-components";
import RoleSelector from "../role-selector";
import { useContext } from "react";
import { UserContext } from "contexts/UserContext";
import { changeRole } from "utils/dataHandler";
import { NotificationContext } from "contexts/NotificationContext";
import useWindowSize from "hooks/useWindowSize";

const CustomTableColumn = styled(EqualTableColumn)`
  :last-child {
    width: 100%;
    text-align: unset;
  }
`;

const ResponsiveRow = styled(TableRow)`
  ${CustomTableColumn} {
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    header {
      font-weight: bold;
    }
    button {
      width: 100%;
    }
  }
`;

const UserRow = user => {
  const { t: translate, i18n } = useTranslation();
  const { notificationModels } = useContext(NotificationContext);
  const {
    state: {
      user: { id: actUserId }
    }
  } = useContext(UserContext);

  moment.locale(i18n.language);

  const { id, registrationDate, name, email, role } = user;

  const [viewDetails, setViewDetails] = useState(false);

  const open = () => {
    setViewDetails(!viewDetails);
  };

  const updateRole = ({ value }) => {
    changeRole({ id, role: value })
      .then(() => notificationModels.successModify())
      .catch(() => notificationModels.serverError());
  };

  const viewbutton = <ViewButton open={viewDetails} onClick={open} />;

  const userDetails = <UserDetails open={viewDetails} {...user} />;

  const roleUpdater = (
    <RoleSelector defaultValue={role} onChange={updateRole} />
  );

  const headers = translate("dashboard.users.headers", { returnObjects: true });

  const columns = [
    id,
    moment(new Date(registrationDate)).format("LLL"),
    name,
    email,
    id === actUserId ? translate(`dashboard.users.roles.${role}`) : roleUpdater,
    viewbutton,
    userDetails
  ];

  const { width } = useWindowSize();

  return width > 1000 ? (
    <TableRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length - 1}>
          {column}
        </CustomTableColumn>
      ))}
    </TableRow>
  ) : (
    <ResponsiveRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length - 1}>
          {index < headers.length - 1 && <header>{headers[index].text}</header>}
          {column}
        </CustomTableColumn>
      ))}
    </ResponsiveRow>
  );
};

export default UserRow;
