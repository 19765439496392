import React, { useEffect, useState } from "react";
import { BaseTable } from "components/utils";
import { useTranslation } from "react-i18next";
import useQueryParams from "hooks/useQueryParams";
import useScrollTop from "hooks/useScrollTop";
import { PRODUCT_SORT_TYPE } from "utils/contants";
import { getAllProduct } from "utils/dataHandler";
import ProductRow from "./product-row";
import EditForm from "./edit-form";
import { useContext } from "react";
import { NotificationContext } from "contexts/NotificationContext";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 10px 0;
`;

const ProductEditor = () => {
  const { t: translate } = useTranslation();
  const { notificationModels } = useContext(NotificationContext);

  const initState = { products: [], count: 0, loading: true };

  const [toEdit, setToEdit] = useState(null);
  const [{ products, count, loading }, setState] = useState(initState);

  const [{ page, sort = "group", desc = "false" }] = useQueryParams();
  const currentPage = page > 0 ? page : 1;

  useScrollTop([currentPage, toEdit]);

  useEffect(() => {
    const sortConfig = {
      by: PRODUCT_SORT_TYPE[sort],
      desc: JSON.parse(desc)
    };
    getAllProduct(currentPage, sortConfig).then(({ products, count }) => {
      setState({ products, count, loading: false });
    });
  }, [currentPage, sort, desc]);

  const handleFinishedModify = newProducts => {
    setState({ count, loading, products: newProducts });
    setToEdit(null);
  };

  const handleEdited = product => {
    const edited = products.map(p => (p.id === product.id ? product : p));
    handleFinishedModify(edited);
    notificationModels.successModify();
  };

  const handleDeleted = id => {
    const edited = products.filter(p => p.id !== id);
    handleFinishedModify(edited);
    notificationModels.successDelete();
  };

  return (
    <Wrapper>
      <EditForm product={toEdit} onEdited={handleEdited} />
      <BaseTable
        loading={loading}
        headers={translate("dashboard.products.headers", {
          returnObjects: true
        })}
        pagination={{ itemsNumber: count }}
      >
        {products.map(product => (
          <ProductRow
            key={product.id}
            product={product}
            onEdit={toEdit => setToEdit(toEdit)}
            onDeleted={handleDeleted}
            isEditing={toEdit && toEdit.id === product.id}
          />
        ))}
      </BaseTable>
    </Wrapper>
  );
};

export default ProductEditor;
