import axios from "axios";
import { createExpireDate } from "./util";
import Product from "models/product.model";
import _ from "lodash";
import { ORDER_SORT_TYPE } from "./contants";

export const BACKEND = process.env.REACT_APP_BACKEND_URL;

axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  const token = getToken();
  if (token) config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

const setToken = token => {
  const u_t = { expire: createExpireDate({ hour: 12 }), token };
  localStorage.setItem("u_t", JSON.stringify(u_t));
};

export const getToken = () => {
  const u_t = JSON.parse(localStorage.getItem("u_t"));
  if (u_t && new Date(u_t.expire) > new Date()) return u_t.token;
  else if (u_t) removeToken();
  return null;
};

export const removeToken = () => localStorage.removeItem("u_t");

export const registration = user => {
  return axios
    .post(`${BACKEND}/user/registrate`, user)
    .then(res => {
      const { errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      return Promise.resolve(res.data);
    })
    .catch(err => Promise.reject(err));
};

export const login = user => {
  return axios
    .post(`${BACKEND}/user/login`, user)
    .then(res => {
      const { token, errorType } = res.data;
      if (errorType) {
        return Promise.reject(errorType);
      } else {
        setToken(token);
        return Promise.resolve("SUCCESS");
      }
    })
    .catch(err => Promise.reject(err));
};

export const logout = () => {
  return axios
    .post(`${BACKEND}/user/logout`, { token: getToken() })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err))
    .finally(() => removeToken());
};

export const getActiveUser = () => {
  return axios
    .get(`${BACKEND}/user/activeuser`)
    .then(res => {
      const { users } = res.data;
      return Promise.resolve(users[0]);
    })
    .catch(err => Promise.reject(err));
};

export const updateUser = user => {
  return axios
    .put(`${BACKEND}/user/update`, user)
    .then(res => {
      const { users } = res.data;
      return Promise.resolve(users[0]);
    })
    .catch(err => Promise.reject(err));
};

export const addNewAddress = address => {
  return axios
    .post(`${BACKEND}/user/address`, address)
    .then(res => {
      const { users } = res.data;
      return Promise.resolve(users[0]);
    })
    .catch(err => Promise.reject(err));
};

export const deleteAddress = id => {
  return axios
    .delete(`${BACKEND}/user/address/${id}`)
    .then(res => {
      const { users } = res.data;
      return Promise.resolve(users[0]);
    })
    .catch(err => Promise.reject(err));
};

export const confirmUser = token => {
  return axios
    .get(`${BACKEND}/user/confirmation/${token}`)
    .then(res => {
      const { errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(res);
    })
    .catch(err => Promise.reject(err));
};

export const sendPasswordChangeLink = user => {
  return axios
    .post(`${BACKEND}/user/forgotPassword`, user)
    .then(res => {
      const { errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(res);
    })
    .catch(err => Promise.reject(err));
};

export const checkPasswordChangeToken = token => {
  return axios
    .get(`${BACKEND}/user/forgotPassword/${token}`)
    .then(res => {
      const { errorType, users } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(users[0]);
    })
    .catch(err => Promise.reject(err));
};

export const changePassword = (user, token) => {
  return axios
    .put(`${BACKEND}/user/forgotPassword/${token}`, user)
    .then(res => {
      const { errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(res);
    })
    .catch(err => Promise.reject(err));
};

export const getCart = () => {
  const localCart = JSON.parse(localStorage.getItem("cart")) || {};
  if (localCart && new Date(localCart.expire) > new Date()) {
    return {
      ...localCart,
      expire: createExpireDate({ hour: 5 }),
      products: localCart.products.map(product => new Product(product))
    };
  }
  deleteCart();
  return { expire: createExpireDate({ hour: 5 }), products: [], coupon: null };
};

export const setCart = cart => {
  if (_.isEmpty(cart.products)) deleteCart();
  else localStorage.setItem("cart", JSON.stringify(cart));
};

export const deleteCart = () => {
  localStorage.removeItem("cart");
};

export const setCoupon = coupon => {
  const cart = getCart();
  cart.coupon = coupon;
  setCart(cart);
  return cart;
};

export const addToCart = product => {
  const cart = getCart();
  const { products } = cart;
  products.push(product);
  setCart(cart);
  return cart;
};

export const removeProductFromCart = product => {
  const cart = getCart();
  const { products } = cart;
  const lastAppear = products.map(p => p.name).lastIndexOf(product.name);
  products.splice(lastAppear, 1);
  setCart(cart);
  return cart;
};

export const removeAllFromCart = product => {
  const cart = getCart();
  const { products } = cart;
  cart.products = products.filter(p => p.id !== product.id);
  setCart(cart);
  return cart;
};

export const getFoods = () => {
  return axios
    .get(`${BACKEND}/product/foods`)
    .then(res => {
      const { products } = res.data;
      return Promise.resolve(products);
    })
    .catch(err => Promise.reject(err));
};

export const getDrinks = () => {
  return axios
    .get(`${BACKEND}/product/drinks`)
    .then(res => {
      const { products } = res.data;
      return Promise.resolve(products);
    })
    .catch(err => Promise.reject(err));
};

export const calculatePrices = order => {
  return axios
    .post(`${BACKEND}/order/calculate`, order)
    .then(res => {
      const { orders, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(orders[0]);
    })
    .catch(err => Promise.reject(err));
};

export const sendOrder = order => {
  return axios
    .post(`${BACKEND}/order/add`, order)
    .then(res => {
      const { url, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(url);
    })
    .catch(err => Promise.reject(err));
};

export const getTodayOrders = () => {
  return axios
    .get(`${BACKEND}/order/today`)
    .then(res => {
      const { orders, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(orders);
    })
    .catch(err => Promise.reject(err));
};

export const getAllOrders = (
  pageNum = 1,
  sortConfig = { by: ORDER_SORT_TYPE.date, desc: true },
  pageSize = 10
) => {
  return axios
    .get(
      `${BACKEND}/order/orders?pageNo=${pageNum - 1}&pageSize=${pageSize}
      &sortBy=${sortConfig.by}&ascending=${!sortConfig.desc}`
    )
    .then(res => {
      const { orders, count, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve({ orders, count });
    })
    .catch(err => Promise.reject(err));
};

export const getAllUsers = (
  pageNum = 1,
  sortConfig = { by: "", desc: true },
  pageSize = 10
) => {
  return axios
    .get(
      `${BACKEND}/user/users?pageNo=${pageNum - 1}&pageSize=${pageSize}
      &sortBy=${sortConfig.by}&ascending=${!sortConfig.desc}`
    )
    .then(res => {
      const { users, count, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve({ users, count });
    })
    .catch(err => Promise.reject(err));
};

export const addNewUser = user => {
  return axios
    .post(`${BACKEND}/user/add`, user)
    .then(res => {
      const { users, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(users);
    })
    .catch(err => Promise.reject(err));
};

export const changeOrderStatus = order => {
  return axios
    .put(`${BACKEND}/order/order`, order)
    .then(res => {
      const { orders, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(orders[0]);
    })
    .catch(err => Promise.reject(err));
};

export const getUserOrders = (
  userId,
  pageNum = 1,
  sortConfig = { by: "", desc: true },
  pageSize = 10
) => {
  return axios
    .get(
      `${BACKEND}/order/userorders/${userId}?pageNo=${
        pageNum - 1
      }&pageSize=${pageSize}&sortBy=${
        sortConfig.by
      }&ascending=${!sortConfig.desc}`
    )
    .then(res => {
      const { orders, count, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve({ orders, count });
    })
    .catch(err => Promise.reject(err));
};

export const changeRole = user => {
  return axios
    .put(`${BACKEND}/user/update-other-user`, user)
    .then(res => {
      const { users, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve(users);
    })
    .catch(err => Promise.reject(err));
};

export const getAllCoupons = () => {
  return axios.get(`${BACKEND}/coupon/coupons`).then(res => res.data);
};

export const addNewCoupon = coupon => {
  return axios
    .post(`${BACKEND}/coupon/coupon`, coupon)
    .then(res => res.data.coupons[0]);
};

export const updateCoupon = coupon => {
  return axios.put(`${BACKEND}/coupon/coupon`, coupon).then(res => res.data);
};

export const getAllProduct = (
  pageNum = 1,
  sortConfig = { by: "", desc: true },
  pageSize = 10
) => {
  return axios
    .get(
      `${BACKEND}/product/products?pageNo=${
        pageNum - 1
      }&pageSize=${pageSize}&sortBy=${
        sortConfig.by
      }&ascending=${!sortConfig.desc}`
    )
    .then(res => {
      const { products, count, errorType } = res.data;
      if (errorType) return Promise.reject(errorType);
      else return Promise.resolve({ products, count });
    });
};

export const addNewProduct = product => {
  return axios
    .post(`${BACKEND}/product/product`, product)
    .then(res => res.data);
};

export const editProduct = product => {
  return axios
    .put(`${BACKEND}/product/product`, product)
    .then(res => res.data.products[0]);
};

export const deleteProduct = id => {
  return axios.delete(`${BACKEND}/product/${id}`).then(res => res.data);
};

export const getZipCodes = () => {
  return axios
    .get(`${BACKEND}/shipping-cost/shipping-costs`)
    .then(res => res.data.map(({ zipCode }) => zipCode));
};

export const getOrdersActivity = () => {
  return axios.get(`${BACKEND}/order/get-active-state`).then(res => res.data);
};

export const setOrdersActivity = () => {
  return axios
    .get(`${BACKEND}/order/change-active-state`)
    .then(res => res.data);
};

export const getRsaKey = () => {
  return axios.get(`${BACKEND}/crypto/publickey`).then(res => res.data);
};

export const getMinOrderValue = () =>
  axios.get(`${BACKEND}/min-order-value`).then(res => res.data);

export const setMinOrderValue = value =>
  axios.post(`${BACKEND}/min-order-value/${value}`).then(res => res.data);

export const getShippingTime = () =>
  axios.get(`${BACKEND}/order/estimated-shipping-time`).then(res => res.data);

export const setShippingTime = time =>
  axios
    .post(`${BACKEND}/order/estimated-shipping-time/${time}`)
    .then(res => res.data);

export const getUserOrderNotes = () =>
  axios.get(`${BACKEND}/user/notes`).then(res => res.data);

export const getOrdersByDate = date =>
  axios.get(`${BACKEND}/order/orders/${date}`).then(res => res.data.orders);

export const getEventLogs = date =>
  axios.get(`${BACKEND}/event/${date}`).then(res => res.data);

export const getStreets = zipCode =>
  axios.get(`${BACKEND}/streets/${zipCode}`).then(res => res.data);
