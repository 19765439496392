import React from "react";
import { getProductsCount } from "utils/util";
import { useTranslation } from "react-i18next";
import { DetailRow } from "components/utils/table";

const OrderProducts = ({ products }) => {
  const { t: translate, i18n } = useTranslation();
  const lang = i18n.language;

  const groupedProducts = getProductsCount(products);

  return (
    <>
      <DetailRow
        boldTitle
        boldText
        title={translate(`orders.productName`)}
        text={translate(`orders.quantity`)}
      />
      {groupedProducts.map(
        ({ product: { name, nameEnglish }, quantity }, index) => (
          <DetailRow
            key={index}
            title={lang === "hu" ? name : nameEnglish ? nameEnglish : name}
            text={quantity}
          />
        )
      )}
    </>
  );
};

export default OrderProducts;
