import React from "react";
import { useContext } from "react";
import { OrderContext } from "contexts/OrderContext";
import { useEffect } from "react";
import useQueryParams from "hooks/useQueryParams";
import { sortOrders } from "utils/util";
import { ORDER_SORT_TYPE } from "utils/contants";
import OrderTable from "components/orders";

const CurrentOrder = () => {
  const {
    state: {
      today: { current: orders },
      loading
    },
    fetchTodayOrders,
    cleanUp
  } = useContext(OrderContext);

  const [{ sort, desc = "true" }] = useQueryParams();

  useEffect(() => {
    fetchTodayOrders();
    return () => cleanUp();
  }, [fetchTodayOrders, cleanUp]);

  const config = {
    key: ORDER_SORT_TYPE[sort],
    desc: JSON.parse(desc)
  };

  return <OrderTable orders={sortOrders(orders, config)} loading={loading} />;
};

export default CurrentOrder;
