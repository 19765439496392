import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import {
  isValidCardDate,
  checkCardType,
  isValidNumber,
  isValidName
} from "utils/validation";
import { removeAllLetterAndSpace } from "utils/util";
import CardTypes from "./card-types";

const Closable = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  ${({ open }) =>
    open &&
    css`
      max-height: 300px;
    `};
`;

const Fields = styled.div`
  max-width: 600px;
  margin: 40px 0;
`;

const CustomTextField = styled(TextField)`
  & label,
  label.Mui-focused:not(.Mui-error),
  .MuiInputBase-root {
    color: ${props => props.theme.colors.secondary};
  }
  & fieldset,
  &:hover fieldset,
  &.Mui-focused fieldset {
    border-color: ${props => props.theme.colors.secondary};
  }
  & .MuiInput-underline {
    &:before,
    &:after,
    &:hover:before {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`;

const InlineFields = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
`;

const CardFields = ({ open, values, onChange, cardErrors, setCardErrors }) => {
  const { t: translate } = useTranslation();
  const { cardNumber, expMonth, expYear, cardHolderName, cvn } = values;

  const [cardType, setCardType] = useState("");

  const formatExpire = (month, year) => {
    return year ? `${month} / ${year}` : month;
  };

  const formatCardNubmer = value => {
    const parts = [];
    for (let i = 0, len = value.length; i < len; i += 4) {
      parts.push(value.substring(i, i + 4));
    }
    if (parts.length) return parts.join(" ");
    else return value;
  };

  const handleNameChange = e => {
    const { value } = e.target;
    isValidName(value) && onChange(e);
  };

  const handleCvvChange = e => {
    const { value } = e.target;
    isValidNumber(value) && onChange(e);
  };

  const handleCardNubmerChange = e => {
    const { value } = e.target;
    const formatted = removeAllLetterAndSpace(value);
    setCardType(checkCardType(formatted));
    e.target.value = formatted;
    onChange(e);
  };

  const handleExpireDateChange = e => {
    const { value } = e.target;
    const formatted = removeAllLetterAndSpace(value);
    const isYear = value.length > 2;
    e.target.name = isYear ? "expYear" : "expMonth";
    e.target.value = isYear ? formatted.substring(2, 4) : formatted;
    onChange(e);
  };

  const checkError = (condition, toCheck, error) => {
    if (condition) setCardErrors({ ...cardErrors, [toCheck]: error });
    else if (cardErrors[toCheck])
      setCardErrors({
        ...cardErrors,
        [toCheck]: null
      });
  };

  const checkCardNumber = () => {
    const isTooShort = cardNumber && cardNumber.length !== 16;
    const isWrongType = cardNumber && !cardType;
    const condition = isTooShort ? isTooShort : isWrongType;
    const error = isTooShort ? "cardNumberTooShort" : "notValidCard";
    checkError(condition, "cardNumber", error);
  };

  const checkExpireDate = () => {
    const condition = expMonth && !isValidCardDate(expMonth, expYear);
    checkError(condition, "expireDate", "wrongDate");
  };

  const checkCvv = () => {
    const condition = cvn && cvn.length !== 3;
    checkError(condition, "cvn", "cvvTooShort");
  };

  return (
    <Closable open={open}>
      <Fields>
        <CardTypes type={cardType} />
        <InlineFields>
          <CustomTextField
            required={open}
            fullWidth
            name="cardNumber"
            label={translate("payment.cardNumber")}
            value={formatCardNubmer(cardNumber)}
            onChange={handleCardNubmerChange}
            onBlur={checkCardNumber}
            error={!!cardErrors.cardNumber}
            helperText={
              cardErrors.cardNumber &&
              translate(`error.card.${cardErrors.cardNumber}`)
            }
            autoComplete="cc-number"
            inputProps={{
              maxLength: 19
            }}
          />
          <CustomTextField
            required={open}
            fullWidth
            name="cardHolderName"
            label={translate("payment.cardHolderName")}
            value={cardHolderName}
            onChange={handleNameChange}
            autoComplete="cc-name"
          />
          <CustomTextField
            required={open}
            fullWidth
            name="expireDate"
            label={translate("payment.cardExpire")}
            value={formatExpire(expMonth, expYear)}
            onChange={handleExpireDateChange}
            onBlur={checkExpireDate}
            error={!!cardErrors.expireDate}
            helperText={
              cardErrors.expireDate &&
              translate(`error.card.${cardErrors.expireDate}`)
            }
            autoComplete="cc-exp"
            inputProps={{
              maxLength: 7
            }}
          />
          <CustomTextField
            required={open}
            fullWidth
            name="cvn"
            label={translate("payment.cardCvv")}
            value={cvn}
            onChange={handleCvvChange}
            onBlur={checkCvv}
            error={!!cardErrors.cvn}
            helperText={
              cardErrors.cvn
                ? translate(`error.card.${cardErrors.cvn}`)
                : translate("payment.cardCvvHelp")
            }
            autoComplete="cc-csc"
            inputProps={{
              maxLength: 3
            }}
          />
        </InlineFields>
      </Fields>
    </Closable>
  );
};

export default CardFields;
