import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const CategorySelector = styled.div`
  min-width: 350px;
  max-width: 360px;
  @media (max-width: 1200px) {
    top: 100px;
  }
  @media (max-width: 900px) {
    max-width: unset;
    min-width: unset;
  }
  @media (max-width: 600px) {
    max-width: 60%;
    min-width: unset;
  }
`;

const CategoryList = styled.div`
  @media (max-width: 900px) {
    max-height: ${props => (props.open ? "unset" : 0)};
    opacity: ${props => (props.open ? "unset" : 0)};
    pointer-events: ${props => !props.open && "none"};
    margin-bottom: 20px;
  }
`;

const Category = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 25px;
  font-family: SpicyRice;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 5px;
  text-align: left;
  color: ${props => props.theme.colors.lightGreen};
  transition: all 0.3s;
  ${p =>
    p.selected &&
    css`
      color: ${p.theme.colors.primary};
      :before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        left: -10px;
        border-radius: 50%;
        transform: translateY(calc(-50% - 3px));
        background-color: ${p.theme.colors.primary};
      }
    `}
  @media (min-width: 900px) {
    :hover {
      color: ${props => props.theme.colors.primary};
    }
  }
  @media (max-width: 900px) {
    font-size: 20px;
    white-space: nowrap;
  }
  @media (max-width: 320px) {
    white-space: unset;
  }
`;

const Select = styled(Category)`
  position: relative;
  display: none;
  padding: 8px 10px;
  border-radius: 0;
  text-transform: unset;
  font-family: EncSansMed;
  letter-spacing: 1px;
  background: ${props => props.theme.colors.lightGreen};
  color: ${props => props.theme.colors.light};
  @media (max-width: 900px) {
    display: block;
    white-space: unset;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 5px 30px 5px 10px;
  }
`;

const Icon = styled(IconContainer)`
  position: absolute;
  top: 50%;
  right: 20px;
  transition: transform 0.3s;
  transform: ${props =>
    props.open ? "translateY(-50%) rotate(-180deg)" : "translateY(-50%)"};
  @media (max-width: 600px) {
    right: 10px;
  }
`;

const ProductCategories = ({ type, group }) => {
  const { t: translate } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <CategorySelector>
      <Select to="#" onClick={() => setOpen(!open)}>
        <Icon open={open}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Icon>
        {translate("foods.choosableCategories")}
      </Select>
      <CategoryList open={open} onClick={() => setOpen(false)}>
        {translate(`${type}.categories`, { returnObjects: true }).map(
          (category, index) => (
            <Category
              key={index}
              to={`/${type}/${category.group}`}
              selected={group === category.group}
            >
              {category.name}
            </Category>
          )
        )}
      </CategoryList>
    </CategorySelector>
  );
};

export default ProductCategories;
