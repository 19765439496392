import React, { useState } from "react";
import { HideContainer, SubTitle } from "style/global.styled";
import ProductForm from "components/dashboard/product-from";
import useFormValues from "hooks/useFormValues";
import { useEffect } from "react";
import { editProduct } from "utils/dataHandler";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Title = styled(SubTitle)`
  text-align: center;
  margin: 50px 0;
`;

const EditForm = ({ product, onEdited }) => {
  const { t: translate } = useTranslation();

  const [editing, setEditing] = useState(false);
  const [values, handleChange, setValues] = useFormValues(product);

  const handleAllergensChange = value => {
    setValues({ ...values, allergenics: value });
  };

  useEffect(() => {
    setValues(product);
  }, [product, setValues]);

  const handleSubmit = e => {
    e.preventDefault();
    setEditing(true);
    editProduct(values)
      .then(res => onEdited(res))
      .catch(err => console.log(err))
      .finally(() => setEditing(false));
  };

  return (
    <HideContainer show={!!product} maxHeight="1680px" duration="0">
      <Title>{translate("products.edit")}</Title>
      {values && (
        <ProductForm
          values={values}
          handleChange={handleChange}
          allergenChange={handleAllergensChange}
          onSubmit={handleSubmit}
          loading={editing}
          edit
        />
      )}
    </HideContainer>
  );
};

export default EditForm;
