import React, { useContext } from "react";
import { Button, Form } from "style/global.styled";
import { AddressGroup } from "components/input-groups";
import Address from "models/address.model";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/UserContext";
import useFormValues from "hooks/useFormValues";

const AddAddress = () => {
  const { t: translate } = useTranslation();
  const { newAddress } = useContext(UserContext);

  const init = new Address({});
  const [values, handleChange] = useFormValues(init);

  const handleSubmit = e => {
    e.preventDefault();
    const address = new Address(values);
    newAddress(address);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <AddressGroup
        cityProps={{
          value: values.city,
          onChange: handleChange
        }}
        streetProps={{
          value: values.street,
          onChange: handleChange
        }}
        zipCodeProps={{
          value: values.zipCode,
          onChange: handleChange
        }}
      />
      <Button type="submit">{translate("add")}</Button>
    </Form>
  );
};

export default AddAddress;
