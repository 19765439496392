import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const CustomSpinner = styled(CircularProgress)`
  .MuiCircularProgress-svg {
    color: ${props =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.secondary};
  }
`;

const Loader = ({ color, size }) => {
  return <CustomSpinner color={color} size={size} />;
};

export default Loader;
