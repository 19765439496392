import React from "react";
import styled from "styled-components";
import useClickAway from "hooks/useClickAway";
import { useRef } from "react";

const NoteContainer = styled.div`
  position: absolute;
  width: 100%;
  margin-top: -25px;
  z-index: 200;
  overflow: auto;
  max-height: 300px;
  background: ${p => p.theme.colors.lightGray};
  border: 1px solid ${p => p.theme.colors.darkGray};
  border-top: 0;
  display: ${p => !p.open && "none"};
`;

const Note = styled.div`
  color: ${p => p.theme.colors.secondary};
  padding: 10px;
  border-top: 1px solid ${p => p.theme.colors.darkGray};
  cursor: pointer;
  :hover {
    background: ${p => p.theme.colors.gray};
  }
`;

const PreviousNotes = ({ open, onClose, notes, onClick }) => {
  const ref = useRef(null);

  const handleClose = () => {
    if (open) {
      onClose();
    }
  };

  useClickAway(ref, handleClose);

  return (
    <NoteContainer open={open} ref={ref}>
      {notes.map(note => (
        <Note key={note} onClick={() => onClick(note)}>
          {note}
        </Note>
      ))}
    </NoteContainer>
  );
};

export default PreviousNotes;
