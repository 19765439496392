import React, { useEffect } from "react";
import { Selector } from "components/utils";
import { useTranslation } from "react-i18next";

const AddressSelect = ({ onChange, addresses }) => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (addresses && addresses.length === 1) {
      onChange(addresses[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  const handleChange = e => {
    const id = e.target.value;
    if (id) {
      const selected = addresses.find(
        address => address.id.toString() === id.toString()
      );
      onChange(selected);
    }
  };

  if (!addresses || addresses.length <= 1) return null;

  return (
    <Selector
      label={translate("form.selectAddress")}
      emptyText={translate("form.noAddressSaved")}
      onChange={handleChange}
    >
      {addresses &&
        addresses.map(({ id, city, street, zipCode }, index) => (
          <option key={index} value={id}>
            {zipCode}, {city}, {street}
          </option>
        ))}
    </Selector>
  );
};

export default AddressSelect;
