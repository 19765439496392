import { useState } from "react";

const useFormValues = initValues => {
  const [values, setValues] = useState(initValues);

  const handleChange = e => {
    let newValue;
    const name = e.target.name;
    if (e.target.type === "checkbox") {
      newValue = e.target.checked;
      setValues({ ...values, [name]: newValue });
    } else {
      newValue = e.target.value;
      setValues({ ...values, [name]: newValue });
    }
  };

  return [values, handleChange, setValues];
};

export default useFormValues;
