import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { checkPasswordChangeToken } from "utils/dataHandler";
import styled from "styled-components";
import User from "models/user.model";
import { Container, Form, SubTitle } from "style/global.styled";
import { PasswordModifier, Message } from "components/utils";
import { SendModicitaionLink } from "components/forgot-password";
import { UserContext } from "contexts/UserContext";

const Wrapper = styled(Container)`
  min-height: 60vh;
  ${Form} {
    max-width: 500px;
  }
  @media (min-width: 500px) {
    padding: 30px;
  }
`;

const ForgotPassword = () => {
  const { t: translate } = useTranslation();
  const { changeForgotPassword } = useContext(UserContext);

  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token)
      checkPasswordChangeToken(token)
        .then(user => {
          setUser(user);
          setLoading(false);
        })
        .catch(err => {
          if (err === "INVALID_TOKEN" || err === "ALREADY_USED_TOKEN")
            setLoading(false);
        });
    else {
      setUser({});
      setLoading(false);
    }
  }, [token]);

  const handleSubmit = values => {
    const updatedUser = new User({ ...user, ...values });
    return changeForgotPassword(updatedUser, token);
  };

  if (loading) return null;

  if (!user)
    return (
      <Wrapper>
        <Message
          title={translate("error.forgotPw.title")}
          text={translate("error.forgotPw.text")}
        />
      </Wrapper>
    );

  return (
    <Wrapper>
      <SubTitle>{translate("forgotPw.title")}</SubTitle>
      {!token ? (
        <SendModicitaionLink />
      ) : (
        <PasswordModifier onSubmit={handleSubmit} />
      )}
    </Wrapper>
  );
};

export default ForgotPassword;
