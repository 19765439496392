import React from "react";
import styled from "styled-components";
import { Button } from "style/global.styled";
import { useTranslation } from "react-i18next";

const ViewBtn = styled(Button)`
  margin: 0;
  font-size: 18px;
  padding: 8px;
  height: unset;
  width: unset;
`;

const ViewButton = ({ open, onClick }) => {
  const { t: translate } = useTranslation();

  return (
    <ViewBtn onClick={onClick}>
      {!open ? translate("view") : translate("close")}
    </ViewBtn>
  );
};

export default ViewButton;
