import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ALLERGEN_ICONS } from "utils/contants";

const Wrapper = styled.div`
  display: grid;
  margin: 50px 15px;
  grid-template-columns: 140px 140px;
  grid-gap: 10px;
  @media (max-width: 900px) {
    margin: 0 5px 30px;
    width: 100%;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;
const Allergen = styled.div`
  display: flex;
  font-family: EncSansMed;
  color: ${props => props.theme.colors.primary};
  span {
    width: 105px;
    margin-left: 10px;
  }
`;

const Img = styled.img`
  width: 25px;
`;

const Allergens = () => {
  const { t: translate } = useTranslation();
  const icons = Object.values(ALLERGEN_ICONS);

  return (
    <Wrapper>
      {translate("foods.allergens", { returnObjects: true }).map(
        (text, index) => (
          <Allergen key={index}>
            <Img src={icons[index]} alt="allergen" />
            <span>{text}</span>
          </Allergen>
        )
      )}
    </Wrapper>
  );
};

export default Allergens;
