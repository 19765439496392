import React, { useEffect } from "react";
import NotificationManager from "./NotificationManager";
import { useState } from "react";
import styled from "styled-components";
import Notification from "./Notification";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1200;
  top: 0;
  right: 0;
`;

const NotificationContainer = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange);
    return () => NotificationManager.removeChangeListener(handleStoreChange);
  });

  const handleStoreChange = notifications => {
    setNotifications(notifications);
  };

  const hideNotification = id => {
    NotificationManager.remove(id);
  };

  return (
    <Container>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          onHide={hideNotification}
          {...notification}
        />
      ))}
    </Container>
  );
};

export default NotificationContainer;
