import React from "react";
import { Container } from "style/global.styled";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useScrollTop from "hooks/useScrollTop";
import { ProductCategories, ProductList, Allergens } from "components/products";
import ProductContextProvider from "contexts/ProductContext";
import { PRODUCT_TYPES } from "utils/contants";

const Cont = styled(Container)`
  min-height: 70vh;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 50px 40px;
  @media (max-width: 1000px) {
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 20px 15px;
  }
`;

const SideMenu = styled.div`
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const Products = () => {
  const { group } = useParams();

  useScrollTop(group);

  return (
    <ProductContextProvider>
      <Cont>
        <SideMenu>
          <ProductCategories type={PRODUCT_TYPES.FOOD} group={group} />
          <Allergens />
        </SideMenu>
        <ProductList type={PRODUCT_TYPES.FOOD} group={group} />
      </Cont>
    </ProductContextProvider>
  );
};

export default Products;
