import React, { useState, useEffect } from "react";
import Selector from "../selector";
import { useTranslation } from "react-i18next";
import { getStreets } from "utils/dataHandler";
import TextField from "../text-field";
import styled from "styled-components";

const Fields = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 100%;
  }
  @media (min-width: 600px) {
    & > * {
      :nth-child(1),
      :nth-child(2) {
        width: 48%;
      }
    }
  }
`;

const StreetField = ({
  name: fieldName,
  value,
  onChange,
  zipCode,
  ...props
}) => {
  const { t: translate } = useTranslation();

  const [streets, setStreets] = useState([]);

  useEffect(() => {
    if (zipCode) {
      getStreets(zipCode).then(streets => {
        resetStreet();
        setStreets(streets);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const getStreetFromValue = () => {
    for (const street of streets) {
      if (value.includes(street)) {
        return street;
      }
    }
    return "";
  };

  const getHouseFromValue = () => {
    const street = getStreetFromValue(value);
    if (street) {
      return value.replace(`${street}`, "").trimStart();
    }
    return value;
  };

  const resetStreet = () => {
    const house = getHouseFromValue();
    const event = {
      target: { name: fieldName, value: house }
    };
    onChange(event);
  };

  const handleChange = e => {
    const { name, value: newValue } = e.target;
    let street = "",
      house = "";
    if (name === "street") {
      street = newValue;
      house = getHouseFromValue();
    } else {
      street = getStreetFromValue();
      house = newValue;
    }
    const event = {
      ...e,
      target: { name: fieldName, value: `${street} ${house}`.trimStart() }
    };
    onChange(event);
  };

  return (
    <Fields>
      <Selector
        name="street"
        value={getStreetFromValue()}
        label={translate("form.street")}
        emptyText={translate("requestInProgress")}
        onChange={handleChange}
        {...props}
      >
        {streets &&
          streets.map((street, index) => (
            <option key={`${street}-${index}`} value={street}>
              {street}
            </option>
          ))}
      </Selector>
      <TextField
        name="house"
        value={getHouseFromValue()}
        label={translate("form.houseNumber")}
        onChange={handleChange}
        {...props}
      />
    </Fields>
  );
};

export default StreetField;
