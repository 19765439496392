import React, { useState, useContext } from "react";
import useFormValues from "hooks/useFormValues";
import User from "models/user.model";
import { sendPasswordChangeLink } from "utils/dataHandler";
import { NotificationContext } from "contexts/NotificationContext";
import styled from "styled-components";
import { TextField, LoaderButton } from "components/utils";
import { useTranslation } from "react-i18next";
import { Form } from "style/global.styled";

const Text = styled.div`
  color: ${props => props.theme.colors.secondary};
`;

const SendModicitaionLink = () => {
  const { notificationModels } = useContext(NotificationContext);
  const { t: translate } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const init = { email: "" };
  const [emailError, setEmailError] = useState("");
  const [values, changeHandler] = useFormValues(init);

  const handleChange = e => {
    if (emailError) setEmailError("");
    changeHandler(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const user = new User(values);
    sendPasswordChangeLink(user)
      .then(() => setSent(true))
      .catch(err => {
        if (err === "WRONG_EMAIL") setEmailError("emailNotExists");
        else notificationModels.serverError();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {!sent ? (
        <>
          <Text>{translate("forgotPw.infoText")}</Text>
          <Form onSubmit={handleSubmit}>
            <TextField
              name="email"
              label={translate("form.email")}
              value={values.email}
              onChange={handleChange}
              error={emailError && translate(`error.${emailError}`)}
              required
            />
            <LoaderButton
              type="submit"
              loading={loading}
              text={translate("send")}
            />
          </Form>
        </>
      ) : (
        <Text>{translate("forgotPw.sentLinkText")}</Text>
      )}
    </>
  );
};

export default SendModicitaionLink;
