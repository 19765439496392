import React from "react";
import { TableRow, EqualTableColumn } from "style/global.styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useContext } from "react";
import { NotificationContext } from "contexts/NotificationContext";
import useWindowSize from "hooks/useWindowSize";
import { BooleanSelector } from "components/utils";
import { updateCoupon } from "utils/dataHandler";

const ResponsiveRow = styled(TableRow)`
  ${EqualTableColumn} {
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    header {
      font-weight: bold;
    }
    button {
      width: 100%;
    }
  }
`;

const CouponRow = ({ code, discount, isActive, onDelete }) => {
  const { t: translate } = useTranslation();
  const { notificationModels } = useContext(NotificationContext);

  const update = (name, value) => {
    updateCoupon({ code, discount, isActive: value })
      .then(() => notificationModels.successModify())
      .catch(() => notificationModels.serverError());
  };

  const headers = translate("dashboard.coupons.headers", {
    returnObjects: true
  });

  const columns = [
    code,
    discount,
    <BooleanSelector defaultValue={isActive} onChange={update} />
  ];

  const { width } = useWindowSize();

  return width > 1000 ? (
    <TableRow>
      {columns.map((column, index) => (
        <EqualTableColumn key={index} totalColumn={columns.length}>
          {column}
        </EqualTableColumn>
      ))}
    </TableRow>
  ) : (
    <ResponsiveRow>
      {columns.map((column, index) => (
        <EqualTableColumn key={index} totalColumn={columns.length}>
          {<header>{headers[index].text}</header>}
          {column}
        </EqualTableColumn>
      ))}
    </ResponsiveRow>
  );
};

export default CouponRow;
