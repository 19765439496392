import React from "react";
import { SubNavigation, SubNavItem } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { Private } from "components/utils";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Navigation = styled(SubNavigation)`
  @media (min-width: 900px) {
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    ${SubNavItem} {
      width: calc(25% - 20px);
      margin: 10px;
      text-align: center;
      display: block;
    }
  }
`;

const DashboardNav = () => {
  const { t: translate } = useTranslation();

  const { subpage } = useParams();

  return (
    <Navigation>
      {translate("dashboard.nav", { returnObjects: true }).map(
        ({ text, link, roles }, index) => (
          <Private key={index} roles={roles}>
            <SubNavItem to={`/dashboard/${link}`} selected={subpage === link}>
              {text}
            </SubNavItem>
          </Private>
        )
      )}
    </Navigation>
  );
};

export default DashboardNav;
