import React from "react";
import { Selector } from "components/utils";
import { ROLES } from "utils/contants";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { UserContext } from "contexts/UserContext";

const RoleSelector = ({ onChange }) => {
  const { t: translate } = useTranslation();
  const {
    state: {
      user: { role: userRole }
    }
  } = useContext(UserContext);

  const ALL_ROLES = Object.values(ROLES);
  const { CUSTOMER, SHOP_ASSISTANT } = ROLES;

  const options = {
    ADMIN: ALL_ROLES,
    MANAGER: [SHOP_ASSISTANT],
    SHOP_ASSISTANT: [CUSTOMER]
  };

  return (
    <>
      <Selector
        name="role"
        label={translate(`dashboard.users.chooseRole`)}
        onChange={onChange}
        required
      >
        {ALL_ROLES.map(
          (role, index) =>
            options[userRole].includes(role) && (
              <option key={index} value={role}>
                {translate(`dashboard.users.roles.${role}`)}
              </option>
            )
        )}
      </Selector>
    </>
  );
};

export default RoleSelector;
