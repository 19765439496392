import React, { useContext } from "react";
import styled from "styled-components";
import { Title, Wrapper } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { ProductCard, Loader } from "components/utils";
import { ProductContext } from "contexts/ProductContext";
import _ from "lodash";

const Products = styled.div`
  width: 75%;
  margin: 0 auto;
  padding: 0 20px 0 100px;
  ${Title} {
    margin: 0 0 30px;
  }
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 5px;
  }
`;

const EmptyText = styled.div`
  font-size: 20px;
  text-align: left;
  font-family: SpicyRice;
  color: ${props => props.theme.colors.lightGreen};
`;

const ProductList = ({ type, group }) => {
  const { t: translate } = useTranslation();
  const {
    state: { loading },
    getProducts
  } = useContext(ProductContext);

  const products = getProducts(type, group).map((food, index) => (
    <ProductCard key={index} isFirst={index === 0} {...food} />
  ));

  return (
    <Products>
      <Title>{translate(`${type}.${group}.title`)}</Title>
      {loading ? (
        <Wrapper>
          <Loader size="60px" />
        </Wrapper>
      ) : _.isEmpty(products) ? (
        <EmptyText>{translate(`products.emptyProducts`)}</EmptyText>
      ) : (
        products
      )}
    </Products>
  );
};

export default ProductList;
