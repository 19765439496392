import { useContext } from "react";
import { UserContext } from "contexts/UserContext";

const Private = ({ children, roles = [] }) => {
  const {
    state: {
      user: { role },
      loading
    }
  } = useContext(UserContext);

  if (loading) return null;

  return roles.includes(role) ? children : null;
};

export default Private;
