import React, { useState, useEffect } from "react";
import { BaseTable } from "components/utils";
import { getAllUsers } from "utils/dataHandler";
import { useTranslation } from "react-i18next";
import UserRow from "./user-row";
import useQueryParams from "hooks/useQueryParams";
import { USER_SORT_TYPE } from "utils/contants";

const Users = () => {
  const { t: translate } = useTranslation();
  const initState = { users: [], count: 0, loading: true };

  const [{ users, count, loading }, setState] = useState(initState);

  const [{ page, sort = "date", desc = "true" }] = useQueryParams();
  const currentPage = page > 0 ? page : 1;

  useEffect(() => {
    const sortConfig = {
      by: USER_SORT_TYPE[sort],
      desc: JSON.parse(desc)
    };
    getAllUsers(currentPage, sortConfig)
      .then(({ users, count }) => {
        setState({ users, count, loading: false });
      })
      .catch(err => console.log(err));
    return () => setState({ users: [], count: 0, loading: true });
  }, [currentPage, sort, desc]);

  return (
    <BaseTable
      loading={loading}
      headers={translate("dashboard.users.headers", { returnObjects: true })}
      pagination={{ itemsNumber: count }}
    >
      {users.map(user => (
        <UserRow key={user.id} {...user} />
      ))}
    </BaseTable>
  );
};

export default Users;
