import React from "react";
import styled from "styled-components";
import { Selector } from "components/utils";
import { SubTitle } from "style/global.styled";
import { PAYMENT_METHOD } from "utils/contants";
import { useTranslation } from "react-i18next";

const CustomSelect = styled(Selector)`
  max-width: 500px;
`;

// const InfoText = styled.div`
//   font-size: 18px;
//   color: ${p => p.theme.colors.orange};
//   font-style: italic;
// `;

const PaymentMethod = ({ values = [], handleChange }) => {
  const { t: translate } = useTranslation();

  const methods = Object.values(PAYMENT_METHOD);

  return (
    <>
      <SubTitle>{translate("payment.method")}</SubTitle>
      {/* <InfoText>{translate("cardPaymentSoon")}</InfoText> */}
      <CustomSelect
        name="payment"
        onChange={handleChange}
        defaultValue={values.payment}
      >
        {methods.map((method, index) => (
          <option key={index} value={method}>
            {translate(`payment.${method}`)}
          </option>
        ))}
      </CustomSelect>
    </>
  );
};

export default PaymentMethod;
