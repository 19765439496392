import React from "react";
import styled, { css } from "styled-components";
import { IconContainer, EqualTableColumn } from "style/global.styled";
import useQueryParams from "hooks/useQueryParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

const Item = styled.div`
  position: relative;
  cursor: pointer;
`;

const CustomTableColumn = styled(EqualTableColumn)`
  ${({ isSorter }) =>
    !isSorter &&
    css`
      ${Item} {
        cursor: default;
      }
      ${IconContainer} {
        display: none;
      }
    `}
`;

const Icon = styled(IconContainer)`
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
`;

const HeadColumn = ({ text, sortBy, totalColumn }) => {
  const [{ sort, desc }, set] = useQueryParams();

  const setSortParams = () => {
    if (sortBy) {
      if (sortBy === sort) setParams(sortBy, !JSON.parse(desc));
      else setParams(sortBy, false);
    }
  };

  const setParams = (sort, desc) => {
    set("sort", sort);
    set("desc", desc);
  };

  const isSorter = !!sortBy;

  return (
    <CustomTableColumn totalColumn={totalColumn} isSorter={isSorter}>
      <Item onClick={isSorter ? setSortParams : null}>
        {text}
        <Icon>
          <FontAwesomeIcon icon={faSort} />
        </Icon>
      </Item>
    </CustomTableColumn>
  );
};

export default HeadColumn;
