import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faAt
} from "@fortawesome/free-solid-svg-icons";
import images from "assets/images";
import { useLocation } from "react-router-dom";

const Wrapper = styled.footer`
  min-height: 10vh;
  min-width: 360px;
  padding: 50px 30px;
  color: ${props => props.theme.colors.light};
  background: url(${images.BackgroundRed});
  background-attachment: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: visible;
  a:hover {
    text-decoration: underline;
  }
  ${p =>
    !p.isHomePage &&
    css`
      border-top: 1px solid #fff;
    `}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
`;

const Item = styled.div`
  margin: 0 40px;
  letter-spacing: 0.5px;
  @media (max-width: 900px) {
    margin: 20px 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Texts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Text = styled.div`
  display: flex;
  margin: 10px;
  ${IconContainer} {
    width: 20px;
    margin-right: 10px;
  }
  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const CopyrightText = styled.div`
  text-align: center;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const Footer = () => {
  const { t: translate } = useTranslation();
  const { pathname } = useLocation();

  const isHomePage = pathname === "/";
  
  library.add(fab);

  const icons = [faAt, faPhone, ["fab", "facebook-f"]];

  const contacts = translate("footer.contacts", { returnObjects: true });
  const openingHours = translate("footer.openingHours", {
    returnObjects: true
  });
  const copyrightTexts = translate("footer.copyrightTexts", {
    returnObjects: true
  });

  return (
    <Wrapper isHomePage={isHomePage}>
      <Content>
        <Item>
            <Title>{translate("footer.openingHoursTitle")}</Title>
            <Texts>
              {openingHours.map((text, index) => (
                <Text key={index}>{text}</Text>
              ))}
            </Texts>
          </Item>
          <Item>
            <Title>{translate("footer.contactsTitle")}</Title>
            <Texts>
              {contacts.map((text, index) => (
                <Text key={index}>
                  <IconContainer>
                    <FontAwesomeIcon icon={icons[index]} />
                  </IconContainer>
                  {index !== contacts.length - 1 ? (
                    <div>{text}</div>
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/Bety%C3%A1ros-106415174608558/"
                    >
                      {text}
                    </a>
                  )}
                </Text>
              ))}
            </Texts>
          </Item>
          <CopyrightText>
          {copyrightTexts.map((item, index) => (
            <span key={index}>
              {item.link ? (
                <a target="_blank" rel="noopener noreferrer" href={item.link}>
                  {item.text}
                </a>
              ) : (
                <span>{item.text}</span>
              )}
              {copyrightTexts.length - 1 > index && <span> | </span>}
            </span>
          ))}
        </CopyrightText>
      </Content>
    </Wrapper>
  );
};

export default Footer;
