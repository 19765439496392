import { createGlobalStyle } from "styled-components";
import EncodeSansCondensedRegular from "assets/fonts/EncodeSansCondensed-Regular.ttf";
import EncodeSansCondensedMedium from "assets/fonts/EncodeSansCondensed-Medium.ttf";
import EncodeSansCondensedBold from "assets/fonts/EncodeSansCondensed-Bold.ttf";
import SpicyRice from "assets/fonts/SpicyRice-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: EncSans;
        src: url(${EncodeSansCondensedRegular});
    }
    @font-face {
        font-family: EncSansMed;
        src: url(${EncodeSansCondensedMedium});
    }
    @font-face {
        font-family: EncSansBold;
        src: url(${EncodeSansCondensedBold});
    }
    @font-face {
        font-family: SpicyRice;
        src: url(${SpicyRice});
    }
    html, body {
        margin: 0;
        font-family: EncSans;
        scroll-behavior: smooth;
    }
    * {
        box-sizing: border-box;
    }
    *:focus {
        outline: none;
    }
    a {
        text-decoration: none;
        color: unset;
    }
    input:invalid {
        box-shadow: none;
    }
`;

export const colors = {
  light: "#ffffff",
  transparentWhite: "rgba(255, 255, 255, 0.6)",
  gray: "#f2eded",
  lightGray: "#f7f2f2",
  darkGray: "#b8b4b4",
  beigeBg: "rgba(255, 241, 211, 0.85)",
  primary: "#971a38",
  transparentPrimary: "#f6e2b970",
  lightBrown: "#A78156",
  lightGreen: "#6b8789",
  secondary: "#3C1412",
  darkerSecondary: "#301e12",
  transparentSecondary: "#673F2670",
  orange: "#DE7104",
  lighterOrange: "#eb9244",
  darkerOrange: "#8e480b",
  shadow: "#673F2620",
  error: "#F5533E",
  success: "#4BB543",
  warning: "#ffae42"
};
