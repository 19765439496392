import React, { useState, createRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { ErrorMsg } from "style/global.styled";
import { useTranslation } from "react-i18next";

const Field = styled.div`
  padding: 18px 0;
  position: relative;
`;

const Select = styled.select`
  width: 100%;
  font-size: 18px;
  height: 45px;
  border: 0;
  padding: 0 5px;
  color: ${props => props.theme.colors.lightGreen};
  background: ${props => props.theme.colors.transparentWhite};
  option {
    font-size: 20px;
  }
  ${props =>
    props.error &&
    css`
      border: 1px solid ${props.theme.colors.error};
    `};
`;

const Selector = ({
  className,
  label,
  children,
  emptyText,
  required,
  ...props
}) => {
  const { t: translate } = useTranslation();

  const [requiredErr, setRequiredErr] = useState(false);

  const selectRef = createRef();

  useEffect(() => {
    const select = selectRef.current;
    if (select && select.value) {
      select.setCustomValidity("");
      setRequiredErr(false);
    }
  }, [selectRef]);

  const handleRequired = e => {
    e.target.setCustomValidity(" ");
    setRequiredErr(true);
  };

  return (
    <Field className={className}>
      <Select
        {...props}
        required={required}
        onInvalid={handleRequired}
        error={requiredErr}
        ref={selectRef}
      >
        <option value="" hidden>
          {label}
          {required && "*"}
        </option>
        {children ? children : <option disabled>{emptyText}</option>}
      </Select>
      <ErrorMsg>{requiredErr && translate("error.required")}</ErrorMsg>
    </Field>
  );
};

export default Selector;
