import React, { useContext } from "react";
import { SubTitle } from "style/global.styled";
import { UserContext } from "contexts/UserContext";
import User from "models/user.model";
import { useTranslation } from "react-i18next";
import { PasswordModifier } from "components/utils";

const PasswordChange = () => {
  const { t: translate } = useTranslation();
  const { update } = useContext(UserContext);

  const handleSubmit = values => {
    const user = new User(values);
    return update(user, "password");
  };

  return (
    <>
      <SubTitle>{translate("personal.changePassword")}</SubTitle>
      <PasswordModifier onSubmit={handleSubmit} />
    </>
  );
};

export default PasswordChange;
