import React, { createContext, useEffect, useReducer } from "react";
import userReducer from "reducers/userReducer";
import {
  getActiveUser,
  logout,
  updateUser,
  addNewAddress,
  deleteAddress,
  changePassword,
  login,
  registration,
  getToken,
  removeToken
} from "utils/dataHandler";
import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";
import useRedirect from "hooks/useRedirect";
import useOrderSocket from "hooks/useOrderSocket";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const redirect = useRedirect();
  const { notificationModels } = useContext(NotificationContext);
  const init = { isLoggedIn: !!getToken(), user: {}, loading: true };

  const [state, dispatch] = useReducer(userReducer, init);
  const [connect, disconnect] = useOrderSocket();

  useEffect(() => {
    if (state.isLoggedIn)
      getActiveUser()
        .then(user => {
          connect(user.role);
          dispatch({
            type: "SET_USER",
            payload: { user }
          });
        })
        .catch(() => {
          removeToken();
          dispatch({ type: "USER_LOGOUT" });
        });
    else dispatch({ type: "SET_LOADING", payload: false });
  }, [state.isLoggedIn, connect]);

  const signUp = user => {
    return registration(user)
      .then(() => {
        notificationModels.successRegistration();
      })
      .catch(err => {
        let errMsg = null;
        if (err === "EMAIL_TAKEN") errMsg = "emailExists";
        else notificationModels.serverError();
        return Promise.reject(errMsg);
      });
  };

  const logIn = user => {
    return login(user)
      .then(() => {
        dispatch({ type: "USER_LOGIN" });
        notificationModels.successLogin();
      })
      .catch(err => {
        let errMsg = null;
        if (err === "INCORRECT_USER") errMsg = "loginError";
        else if (err === "DISABLED_USER") errMsg = "notVerified";
        else notificationModels.serverError();
        return Promise.reject(errMsg);
      });
  };

  const logOut = () => {
    logout().finally(() => {
      disconnect();
      dispatch({ type: "USER_LOGOUT" });
    });
  };

  const update = (user, what) => {
    return updateUser(user)
      .then(user => {
        dispatch({
          type: "SET_USER",
          payload: { user }
        });
        notificationModels.successModification(what);
      })
      .catch(err => notificationModels.serverError());
  };

  const newAddress = address => {
    return addNewAddress(address)
      .then(user => {
        dispatch({
          type: "SET_USER",
          payload: { user }
        });
        notificationModels.successNewAddress();
        redirect("/profile/addresses");
      })
      .catch(err => notificationModels.serverError());
  };

  const removeAddress = id => {
    deleteAddress(id)
      .then(user => {
        dispatch({
          type: "SET_USER",
          payload: { user }
        });
        notificationModels.successDeleteAddress();
        redirect("/profile/addresses");
      })
      .catch(err => notificationModels.serverError());
  };

  const changeForgotPassword = (user, token) => {
    return changePassword(user, token)
      .then(() => {
        notificationModels.successModification("password");
        redirect("/login");
      })
      .catch(err => notificationModels.serverError());
  };

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        signUp,
        logIn,
        logOut,
        update,
        newAddress,
        removeAddress,
        changeForgotPassword
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
