import { useState } from "react";
import { isPasswordValid, isPasswordMatch } from "utils/validation";

const usePasswordCheck = ({ password, confirmPassword }) => {
  const [passworError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const checkPassword = () => {
    if (password && !isPasswordValid(password))
      setPasswordError("weakPassword");
    else setPasswordError("");
    checkPasswordsMatch();
  };

  const checkPasswordsMatch = () => {
    if (confirmPassword && !isPasswordMatch(password, confirmPassword))
      setConfirmError("passwordsNotMatch");
    else setConfirmError("");
  };

  return [checkPassword, checkPasswordsMatch, passworError, confirmError];
};

export default usePasswordCheck;
