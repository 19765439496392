import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import images from "assets/images";

const HomePage = styled.div`
  min-height: calc(100vh - 80px);
  background-image: url(${images.BackgroundRed});
  background-attachment: fixed;
  min-width: 360px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    margin-top: 10vh;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 70px;
  width: 350px;
  text-align: center;
  color: ${p => p.theme.colors.light};
  @media (max-width: 900px) {
    bottom: 0;
  }
  @media (max-width: 600px) {
    bottom: -70px;
    transform: scale(0.8);
  }
  @media (max-width: 400px) {
    bottom: -100px;
    transform: scale(0.6s);
  }
`;

const Title = styled.div`
  font-family: SpicyRice;
  font-size: 25px;
`;

const Text = styled.div`
  font-size: 18px;
`;

const Logo = styled.img`
  width: 1600px;
  object-fit: cover;
  @media (max-width: 900px) {
    width: 1200px;
  }
  @media (max-width: 600px) {
    width: 800px;
  }
  @media (max-width: 400px) {
    width: 700px;
  }
`;

function Home() {
  const { t: translate } = useTranslation();

  return (
    <HomePage>
      <Wrapper>
        <Logo src={images.LogoHome} />
        <Content>
          <Title>{translate("home.title")}</Title>
          {translate("home.texts", { returnObjects: true }).map(text => (
            <Text key={text}>{text}</Text>
          ))}
        </Content>
      </Wrapper>
    </HomePage>
  );
}

export default Home;
