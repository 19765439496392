import React from "react";
import { useTranslation } from "react-i18next";
import { ALLERGENS } from "utils/contants";
import styled from "styled-components";
import { SubTitle, HideContainer } from "style/global.styled";

const Wrapper = styled.div`
  margin: 30px 0;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 180px);
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const Checkbox = styled.div`
  font-size: 20px;
  color: ${props => props.theme.colors.secondary};
  input {
    width: 16px;
    height: 16px;
  }
`;

const AllergenMarker = ({ values, onChange, show }) => {
  const { t: translate } = useTranslation();

  const allergensLabel = translate("foods.allergens", { returnObjects: true });
  const allergens = Object.values(ALLERGENS);

  const handleChange = e => {
    const { value, checked } = e.target;
    let newValues;
    if (checked) newValues = [...values, value];
    else newValues = values.filter(v => v !== value);
    onChange(newValues);
  };

  return (
    <HideContainer show={show} maxHeight="235px">
      <SubTitle>{translate("products.allergensTitle")}</SubTitle>
      <Wrapper>
        {allergensLabel.map((label, index) => (
          <Checkbox key={index}>
            <input
              id={allergens[index]}
              name="allergenics"
              type="checkbox"
              onChange={handleChange}
              value={allergens[index]}
              checked={values.includes(allergens[index])}
            />
            <label htmlFor={allergens[index]}>{label}</label>
          </Checkbox>
        ))}
      </Wrapper>
    </HideContainer>
  );
};

export default AllergenMarker;
