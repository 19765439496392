import React, { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import { getFoods, getDrinks } from "utils/dataHandler";
import { PRODUCT_GROUP } from "utils/contants";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export const ProductContext = createContext();

const ProductContextProvider = ({ children }) => {
  const { t: translate } = useTranslation();

  const init = { foods: [], drinks: [], loading: true };
  const [state, dispatch] = useReducer(productReducer, init);

  useEffect(() => {
    axios
      .all([getFoods(), getDrinks()])
      .then(products =>
        dispatch({
          type: "SET_PRODUCTS",
          payload: { foods: products[0], drinks: products[1] }
        })
      )
      .catch(() =>
        dispatch({
          type: "SET_PRODUCTS",
          payload: { foods: [], drinks: [] }
        })
      );
  }, []);

  const getProducts = (type, group) => {
    const { loading, [type]: products } = state;
    if (!loading && _.isEmpty(products)) {
      const products = translate(`${type}.${group}.products`, {
        returnObjects: true
      });
      return typeof products === "string" ? [] : products;
    }
    return products.filter(p => p.group === PRODUCT_GROUP[group]);
  };

  return (
    <ProductContext.Provider value={{ state, dispatch, getProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;

const productReducer = (state, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return { ...action.payload, loading: false };
    default:
      return state;
  }
};
