import React from "react";
import { Row } from "style/global.styled";
import styled from "styled-components";

const Text = styled.div`
  display: ${props => !props.children && "none"};
  font-weight: ${props => props.bold && "bold"};
  :last-child {
    text-align: right;
  }
`;

const DetailRow = ({ title, text, boldTitle, boldText, vertical }) => {
  return (
    <Row vertical={vertical}>
      <Text bold={boldTitle}>{title}</Text>
      <Text bold={boldText}>{text}</Text>
    </Row>
  );
};

export default DetailRow;
