import React from "react";
import { SubTitle, SlideContainer, Text } from "style/global.styled";
import { useTranslation } from "react-i18next";
import OrderProducts from "./order-products";
import styled from "styled-components";
import { DetailRow } from "components/utils/table";
import moment from "moment";

const Details = styled(SlideContainer)`
  width: 90%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 5px;
    margin: 0;
  }
`;

const DeliveryTime = styled(Text)`
  font-weight: bold;
`;

const OrderDetails = ({
  address,
  products,
  user,
  name,
  phone,
  email,
  note,
  payment: method,
  payed,
  coupon,
  open,
  originalPrice,
  discountedPrice,
  shippingCost,
  total,
  preOrderDate
}) => {
  const { t: translate } = useTranslation();

  const { city, street, zipCode } = address;

  const orderDetails = [
    { title: "note", text: note || translate(`isNot`) },
    { title: "coupon", text: coupon ? coupon.code : translate(`isNot`) },
    { title: "payment", text: translate(`payment.${method}`) },
    {
      title: "payed",
      text: payed ? translate(`yes`) : translate(`no`)
    },
    {
      title: "original",
      text: `${originalPrice} ${translate(`forint`)}`
    },
    {
      title: "discount",
      text: `${discountedPrice - originalPrice} ${translate(`forint`)}`
    },
    {
      title: "delivery",
      text: `${shippingCost} ${translate(`forint`)}`
    },
    {
      title: "total",
      text: `${total} ${translate(`forint`)}`
    }
  ];

  const customerDetails = [
    { title: "name", text: user ? user.name : name },
    { title: "email", text: user ? user.email : email },
    { title: "phone", text: user ? user.phone : phone },
    { title: "address", text: `${zipCode}, ${city}, ${street}` }
  ];

  return (
    <Details open={open}>
      {preOrderDate && (
        <DeliveryTime>
          {translate("payment.orderDateTitle")}:{" "}
          {moment(new Date(preOrderDate)).format("YYYY. MMMM Do HH:mm")}
        </DeliveryTime>
      )}
      <SubTitle>{translate(`orders.orderDetails`)}</SubTitle>
      <OrderProducts products={products} />
      {orderDetails.map(({ title, text }, index) => (
        <DetailRow
          key={index}
          boldTitle
          title={`${translate(`orders.${title}`)}:`}
          text={text}
          vertical={title === "note" && !!note}
        />
      ))}
      <SubTitle>{translate(`orders.customerDetails`)}</SubTitle>
      {customerDetails.map(({ title, text }, index) => (
        <DetailRow
          key={index}
          boldTitle
          title={`${translate(`orders.${title}`)}:`}
          text={text}
        />
      ))}
    </Details>
  );
};

export default OrderDetails;
