import React, { useState, useContext } from "react";
import { LoaderButton, LegalCheckbox } from "components/utils";
import styled from "styled-components";
import { SubTitle } from "style/global.styled";
import { useTranslation } from "react-i18next";
import Address from "models/address.model";
import User from "models/user.model";
import {
  PersonalInfoGroup,
  AddressGroup,
  PasswordGroup
} from "components/input-groups";
import { isEmailValid } from "utils/validation";
import {
  faEnvelope,
  faLock,
  faUser,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import usePasswordCheck from "hooks/usePasswordCheck";
import useFormValues from "hooks/useFormValues";
import { UserContext } from "contexts/UserContext";

const Title = styled.div`
  font-size: 40px;
  margin: 30px 0;
  font-family: SpicyRice;
  color: ${props => props.theme.colors.primary};
`;

const SignUp = () => {
  const { t: translate } = useTranslation();
  const { signUp } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const initValues = {
    name: "",
    phone: "",
    email: "",
    city: "",
    street: "",
    zipCode: "",
    terms: false,
    password: "",
    confirmPassword: ""
  };

  const [emailError, setEmailError] = useState("");
  const [values, handleChange, setValues] = useFormValues(initValues);

  const [
    checkPassword,
    checkPasswordsMatch,
    passworError,
    confirmError
  ] = usePasswordCheck(values);

  const isEverythingValid = !emailError && !passworError && !confirmError;

  const handleSubmit = e => {
    e.preventDefault();
    if (isEverythingValid) {
      setLoading(true);
      const address = new Address(values);
      const user = new User({ ...values, addresses: [address] });
      signUp(user)
        .then(() => setValues(initValues))
        .catch(err => setEmailError(err))
        .finally(() => setLoading(false));
    }
  };

  const checkEmailValidation = () => {
    const { email } = values;
    if (email && !isEmailValid(email)) setEmailError("emailInvalid");
    else setEmailError("");
  };

  return (
    <div>
      <Title>{translate("signup")}</Title>
      <form onSubmit={handleSubmit}>
        <PersonalInfoGroup
          nameProps={{
            value: values.name,
            icon: faUser,
            onChange: handleChange
          }}
          emailProps={{
            value: values.email,
            icon: faEnvelope,
            onChange: handleChange,
            onBlur: checkEmailValidation,
            error: emailError && translate(`error.${emailError}`)
          }}
          phoneProps={{
            value: values.phone,
            icon: faPhone,
            onChange: handleChange
          }}
        />
        <PasswordGroup
          passwordProps={{
            value: values.password,
            icon: faLock,
            onChange: handleChange,
            onBlur: checkPassword,
            error: passworError && translate(`error.${passworError}`)
          }}
          confrimProps={{
            value: values.confirmPassword,
            icon: faLock,
            onChange: handleChange,
            onBlur: checkPasswordsMatch,
            error: confirmError && translate(`error.${confirmError}`)
          }}
        />
        <SubTitle color="dark">{translate("form.shippingAddress")}</SubTitle>
        <AddressGroup
          cityProps={{
            value: values.city,
            onChange: handleChange
          }}
          streetProps={{
            value: values.street,
            onChange: handleChange
          }}
          zipCodeProps={{
            value: values.zipCode,
            onChange: handleChange
          }}
        />
        <LegalCheckbox value={values.terms} onChange={handleChange} />
        <LoaderButton
          type="submit"
          loading={loading}
          text={translate("signup")}
        />
      </form>
    </div>
  );
};

export default SignUp;
