import { useState, useContext } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { BACKEND } from "utils/dataHandler";
import { useCallback } from "react";
import { ROLES } from "utils/contants";
import { OrderContext } from "contexts/OrderContext";

const { ADMIN, MANAGER, SHOP_ASSISTANT } = ROLES;

const useOrderSocket = () => {
  const [stompClient, setStompClient] = useState(null);
  const { addOrder } = useContext(OrderContext);

  const connect = useCallback(role => {
    if ([ADMIN, MANAGER, SHOP_ASSISTANT].includes(role)) {
      const sock = new SockJS(`${BACKEND}/order-notification-websocket`);
      const stomp = Stomp.over(sock);
      stomp.debug = null;
      stomp.connect({}, () => {
        setStompClient(stomp);
        stomp.subscribe("/notification/order", res => {
          addOrder(JSON.parse(res.body));
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  const disconnect = () => {
    if (stompClient) {
      stompClient.disconnect();
      setStompClient(null);
    }
  };

  return [connect, disconnect];
};

export default useOrderSocket;
