import React from "react";
import TextField from "../text-field";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Link = styled.a`
  font-style: italic;
  color: ${props => props.theme.colors.primary};
  :hover {
    text-decoration: underline;
  }
`;

const LegalCheckbox = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  const label = translate("form.terms", { returnObjects: true }).map(
    (item, index) =>
      item.link ? (
        <Link
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          href={item.link}
        >
          {item.text}
        </Link>
      ) : (
        item.text
      )
  );

  return (
    <TextField
      type="checkbox"
      name="terms"
      label={label}
      value={value}
      required
      onChange={onChange}
    />
  );
};

export default LegalCheckbox;
