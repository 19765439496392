import React, { useState } from "react";
import { PasswordGroup } from "components/input-groups";
import { useTranslation } from "react-i18next";
import useFormValues from "hooks/useFormValues";
import usePasswordCheck from "hooks/usePasswordCheck";
import { Form } from "style/global.styled";
import LoaderButton from "../loader-button";

const PasswordModifier = ({ onSubmit, inputColor }) => {
  const { t: translate } = useTranslation();
  const [updating, setUpdating] = useState(false);

  const init = { password: "", confirmPassword: "" };

  const [values, handleChange, setValues] = useFormValues(init);
  const [
    checkPassword,
    checkPasswordsMatch,
    passworError,
    confirmError
  ] = usePasswordCheck(values);

  const handleSubmit = e => {
    e.preventDefault();
    setUpdating(true);
    if (!passworError && !confirmError) {
      onSubmit(values).finally(() => {
        setValues(init);
        setUpdating(false);
      });
    }
  };

  return (
    <Form bg={inputColor} onSubmit={handleSubmit}>
      <PasswordGroup
        newPw
        passwordProps={{
          value: values.password,
          onChange: handleChange,
          onBlur: checkPassword,
          error: passworError && translate(`error.${passworError}`)
        }}
        confrimProps={{
          value: values.confirmPassword,
          onChange: handleChange,
          onBlur: checkPasswordsMatch,
          error: confirmError && translate(`error.${confirmError}`)
        }}
      />
      <LoaderButton
        type="submit"
        loading={updating}
        text={translate("personal.editPassword")}
      />
    </Form>
  );
};

export default PasswordModifier;
