import React from "react";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";

const PersonalInfoGroup = ({
  className,
  nameProps,
  emailProps,
  phoneProps
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className={className}>
      <TextField
        name="name"
        label={translate("form.name")}
        required
        {...nameProps}
      />
      <TextField
        name="email"
        label={translate("form.email")}
        required
        {...emailProps}
      />
      <TextField
        type="tel"
        name="phone"
        label={translate("form.phone")}
        required
        {...phoneProps}
      />
    </div>
  );
};

export default PersonalInfoGroup;
