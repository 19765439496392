import React from "react";
import styled, { css } from "styled-components";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidPhoneNumber, isValidNumber } from "utils/validation";
import { forwardRef } from "react";

const Field = styled.div`
  height: 45px;
  font-size: 22px;
  position: relative;
  color: ${props => props.theme.colors.lightGreen};
  ${props =>
    props.theme.isError &&
    css`
      border: 1px solid ${props.theme.colors.error};
    `};
`;

const Input = styled.input`
  border: 0;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  padding-left: ${props => (props.theme.hasIcon ? "50px" : "10px")};
  font-size: 22px;
  color: ${props => props.theme.colors.lightGreen};
  background: ${props => props.theme.colors.transparentWhite};
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const Label = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: ${props => (props.theme.hasIcon ? "45px" : "10px")};
  transform: translateY(-55%);
  transition: all 0.5s;
  pointer-events: none;
  color: ${props =>
    props.theme.isError
      ? props.theme.colors.error
      : props.theme.colors.lightGreen};
  ${Input}:focus ~ &,
  ${Input}:not([value=""]) ~ & {
    transform: translate(0);
    top: -30px;
    margin: 0;
    font-size: 18px;
    color: ${props =>
      props.theme.isError
        ? props.theme.colors.error
        : props.theme.colors.lightGreen};
  }
`;

const Icon = styled(IconContainer)`
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0 10px;
  font-size: 22px;
  transform: translate(0, -50%);
`;

const Textbox = forwardRef((props, ref) => {
  const { type, label, required, onChange, maxLength, icon } = props;

  const handleChange = e => {
    const value = e.target.value;
    if (type === "number" && !isValidNumber(value)) {
      return;
    }
    if (type === "tel" && !isValidPhoneNumber(value)) {
      return;
    }
    if (value.length > maxLength) {
      e.target.value = value.slice(0, maxLength);
    }
    onChange(e);
  };

  const IconComponent = icon && (
    <Icon>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  );

  return (
    <Field>
      {IconComponent}
      <Input
        {...props}
        ref={ref}
        type={type === "number" ? "" : type}
        onChange={handleChange}
      />
      <Label>
        {label}
        {required && "*"}
      </Label>
    </Field>
  );
});

export default Textbox;
