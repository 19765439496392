import React, { useState, useContext } from "react";
import { PersonalInfoGroup, AddressGroup } from "components/input-groups";
import { SubTitle } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { faUser, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { AddressSelect } from "components/profile/addresses";
import styled, { css } from "styled-components";
import { isEmailValid } from "utils/validation";
import { UserContext } from "contexts/UserContext";

const UserDetails = styled.div`
  @media (min-width: 600px) {
    display: flex;
    & > * {
      width: 50%;
      :nth-child(1) {
        padding-right: 20px;
      }
      :nth-child(2) {
        padding-left: 20px;
      }
    }
  }
`;

const CustomAddressGroup = styled(AddressGroup)`
  ${p =>
    p.minimized &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > *:nth-child(1),
      & > *:nth-child(2) {
        width: 48%;
      }
    `}
`;

const ShippingInfo = ({ values = [], handleChange, setValues }) => {
  const { name, email, phone, city, street, zipCode } = values;

  const { t: translate } = useTranslation();
  const {
    state: {
      isLoggedIn,
      user: { addresses }
    }
  } = useContext(UserContext);

  const [readOnlyErr, setReadOnlyErr] = useState("");
  const [emailError, setEmailError] = useState("");

  const setAddress = ({ city, street, zipCode }) => {
    setValues({ ...values, city, street, zipCode });
  };

  const handleEmailonBlur = () => {
    if (email && !isEmailValid(email)) setEmailError("emailInvalid");
    else setEmailError("");
    if (readOnlyErr) removeReadOnlyErr();
  };

  const handleReadOnly = () => {
    if (isLoggedIn) setReadOnlyErr("immutable.personalInfo");
  };

  const removeReadOnlyErr = () => {
    setReadOnlyErr("");
  };

  return (
    <>
      <SubTitle>{translate("payment.shippingInfo")}</SubTitle>
      <UserDetails>
        <PersonalInfoGroup
          nameProps={{
            value: name,
            icon: faUser,
            onChange: handleChange,
            onFocus: handleReadOnly,
            onBlur: removeReadOnlyErr,
            readOnly: isLoggedIn,
            error: !!readOnlyErr
          }}
          emailProps={{
            value: email,
            icon: faEnvelope,
            onChange: handleChange,
            onFocus: handleReadOnly,
            onBlur: handleEmailonBlur,
            readOnly: isLoggedIn,
            error: emailError ? translate(`error.${emailError}`) : !!readOnlyErr
          }}
          phoneProps={{
            value: phone,
            icon: faPhone,
            onChange: handleChange,
            onFocus: handleReadOnly,
            onBlur: removeReadOnlyErr,
            readOnly: isLoggedIn,
            error: readOnlyErr && translate(`error.${readOnlyErr}`)
          }}
        />
        <div>
          <AddressSelect addresses={addresses} onChange={setAddress} />
          <CustomAddressGroup
            minimized={addresses && addresses.length > 1}
            cityProps={{
              value: city,
              onChange: handleChange
            }}
            streetProps={{
              value: street,
              onChange: handleChange
            }}
            zipCodeProps={{
              value: zipCode,
              onChange: handleChange
            }}
          />
        </div>
      </UserDetails>
    </>
  );
};

export default ShippingInfo;
