import React from "react";
import { Pagination as MuiPagination } from "@material-ui/lab";
import styled from "styled-components";
import useQueryParams from "hooks/useQueryParams";
import { Redirect, useLocation } from "react-router-dom";

const CustomPagination = styled(MuiPagination)`
  display: flex;
  margin: 40px 10px 0;
  justify-content: center;
  & .MuiPaginationItem-root {
    color: ${props => props.theme.colors.secondary};
  }
`;

const Pagination = ({ itemsNumber = 0, itemPerPage = 10 }) => {
  const { pathname } = useLocation();
  const [{ page = 1 }, set, remove] = useQueryParams();

  const totalPages = Math.ceil(itemsNumber / itemPerPage);
  const currentPage = parseInt(page);

  const setPage = (e, pageNum) => {
    if (pageNum === 1) remove("page");
    else set("page", pageNum);
  };

  if (currentPage > totalPages || currentPage < 1)
    return <Redirect to={pathname} />;

  return (
    <CustomPagination
      onChange={setPage}
      page={currentPage}
      count={totalPages}
    />
  );
};

export default Pagination;
