import React from "react";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { confirmUser } from "utils/dataHandler";
import styled from "styled-components";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Container, Button } from "style/global.styled";
import { useTranslation } from "react-i18next";
import { Message } from "components/utils";

const Wrapper = styled(Container)`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${Button} {
    margin-top: 40px;
  }
`;

const Confirm = () => {
  const { token } = useParams();
  const { t: translate } = useTranslation(null);
  const [verified, setVerified] = useState(null);

  useEffect(() => {
    confirmUser(token)
      .then(() => setVerified({ success: true }))
      .catch(err => {
        if (err === "WRONG_CONFIRMATION_CODE" || err === "ALREADY_CONFIRMED")
          setVerified({ success: false });
        else console.log(err);
      });
  }, [token]);

  if (!verified) return null;

  return (
    <Wrapper>
      {verified.success ? (
        <>
          <Message
            icon={faCheck}
            title={translate("success.confrimation.title")}
            text={translate("success.confrimation.text")}
          />
          <Link to="/login">
            <Button>{translate("login")}</Button>
          </Link>
        </>
      ) : (
        <Message
          icon={faTimes}
          title={translate("error.confrimation.title")}
          text={translate("error.confrimation.text")}
        />
      )}
    </Wrapper>
  );
};

export default Confirm;
