import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PersonalInfoGroup, PasswordGroup } from "components/input-groups";
import { LoaderButton } from "components/utils";
import { Button } from "style/global.styled";
import RoleSelector from "./role-selector";
import useFormValues from "hooks/useFormValues";
import usePasswordCheck from "hooks/usePasswordCheck";
import useEmailValidation from "hooks/useEmailValidation";
import { addNewUser } from "utils/dataHandler";
import User from "models/user.model";
import { NotificationContext } from "contexts/NotificationContext";

const Container = styled.div`
  margin: 0 auto;
  ${Button} {
    width: 100%;
  }
  @media (min-width: 900px) {
    width: 90%;
  }
`;

const FieldConainer = styled.div`
  margin: 60px 0;
  @media (min-width: 768px) {
    display: flex;
    & > * {
      width: 50%;
      :nth-child(1) {
        padding-right: 20px;
      }
      :nth-child(2) {
        padding-left: 20px;
      }
    }
  }
`;

const UserAddition = () => {
  const { t: translate } = useTranslation();
  const { notificationModels } = useContext(NotificationContext);

  const initValues = {
    role: "",
    name: "",
    phone: "",
    email: "",
    city: "",
    street: "",
    zipCode: "",
    password: "",
    confirmPassword: ""
  };

  const [loading, setLoading] = useState(false);
  const [values, handleChange, setValues] = useFormValues(initValues);

  const [
    checkPassword,
    checkPasswordsMatch,
    passwordError,
    confirmError
  ] = usePasswordCheck({ ...values });
  const [emailError, checkEmailValidation, setEmailError] = useEmailValidation(
    values.email
  );

  const isEverythingValid = !emailError && !passwordError && !confirmError;

  const handleSubmit = e => {
    e.preventDefault();
    if (isEverythingValid) {
      setLoading(true);
      const user = new User({ ...values, addresses: [] });
      addNewUser(user)
        .then(() => {
          setValues(initValues);
          notificationModels.successAddUser();
        })
        .catch(err => {
          if (err === "EMAIL_TAKEN") setEmailError("emailExists");
          else notificationModels.serverError();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FieldConainer>
          <PersonalInfoGroup
            nameProps={{ value: values.name, onChange: handleChange }}
            emailProps={{
              value: values.email,
              onChange: handleChange,
              onBlur: checkEmailValidation,
              error: emailError && translate(`error.${emailError}`)
            }}
            phoneProps={{ value: values.phone, onChange: handleChange }}
          />
          <div>
            <RoleSelector onChange={handleChange} />
            <PasswordGroup
              passwordProps={{
                value: values.password,
                onChange: handleChange,
                onBlur: checkPassword,
                error: passwordError && translate(`error.${passwordError}`)
              }}
              confrimProps={{
                value: values.confirmPassword,
                onChange: handleChange,
                onBlur: checkPasswordsMatch,
                error: confirmError && translate(`error.${confirmError}`)
              }}
            />
          </div>
        </FieldConainer>
        <LoaderButton
          type="submit"
          loading={loading}
          text={translate(`dashboard.users.addUser`)}
        />
      </form>
    </Container>
  );
};

export default UserAddition;
