import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Private } from "components/utils";
import { ROLES, ORDER_STATE } from "utils/contants";
import { OrderContext } from "contexts/OrderContext";
import { useTranslation } from "react-i18next";
import { IconContainer } from "style/global.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import images from "assets/images";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  @media (max-width: 500px) {
    transition-property: width;
    transition-delay: ${({ open }) => !open && "1s"};
    width: ${({ open }) => (open ? "100%" : "65px")};
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 450px;
  max-height: 250px;
  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  overflow: hidden;
  transition: max-width 1s ease, max-height 1s ease;
  background-image: url(${images.BackgroundGreen});
  background-attachment: fixed;
  letter-spacing: 1px;
  ${({ open }) =>
    !open &&
    css`
      max-width: 45px;
      max-height: 45px;
    `}
  svg {
    transition: transform 0.5s ease;
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(135deg)")};
  }
`;

const Content = styled.div`
  min-width: 430px;
  min-height: 210px;
  @media (max-width: 500px) {
    min-width: 280px;
  }
`;

const Detail = styled.div`
  display: flex;
  margin: 10px;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  div:nth-child(2) {
    font-size: 23px;
  }
`;

const LinkText = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin: 10px 0 18px 0;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary};
`;

const Icon = styled(IconContainer)`
  position: absolute;
  cursor: pointer;
  font-size: 25px;
  top: 5px;
  left: 5px;
  border: 3px solid;
  border-radius: 6px;
  width: 35px;
  height: 35px;
  color: ${props => props.theme.colors.primary};
`;

const Monitor = () => {
  const { t: translate } = useTranslation();
  const { state, getOrdersNumber } = useContext(OrderContext);

  const [open, setOpen] = useState(true);
  const [ordersNumber, setOrdersNumber] = useState({
    NEW: 0,
    PREPARING: 0,
    DELIVERING: 0,
    DELIVERED: 0
  });

  useEffect(() => {
    getOrdersNumber().then(res => {
      res && setOrdersNumber(res);
    });
  }, [getOrdersNumber, state]);

  const ALL_STATE = Object.values(ORDER_STATE);
  const links = {
    NEW: "current-orders",
    PREPARING: "current-orders",
    DELIVERING: "done-orders",
    DELIVERED: "done-orders"
  };

  return (
    <Container open={open}>
      <Wrapper open={open}>
        <Icon onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Icon>
        <Content>
          <Title>{translate(`dashboard.orderMonitor.title`)}</Title>
          {ALL_STATE.map((state, index) => (
            <Detail key={index}>
              <LinkText to={`/dashboard/${links[state]}`}>
                {translate(`dashboard.orderMonitor.${state}`)}:
              </LinkText>
              <div>{ordersNumber[state]}</div>
            </Detail>
          ))}
        </Content>
      </Wrapper>
    </Container>
  );
};

const OrderMonitor = () => {
  const { ADMIN, MANAGER, SHOP_ASSISTANT } = ROLES;

  return (
    <Private roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}>
      <Monitor />
    </Private>
  );
};

export default OrderMonitor;
